// HeadComponent.js
import React from 'react';
import { Helmet } from 'react-helmet';

const HeadComponent = () => {
  return (
    <Helmet>
      <title>novachain - Run AI Agents like a boss!</title>

      <meta charset="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="Bootstrap 4 Mobile App Template" />
      <meta name="author" content="Xiaoying Riley at 3rd Wave Media" />
      <link rel="shortcut icon" href="favicon.ico" />
      <link
        href="https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700|Nunito:400,600,700"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="assets/plugins/jquery-flipster/dist/jquery.flipster.min.css"
      />
      <link id="theme-style" rel="stylesheet" href="assets/css/theme.css" />
      
      <script
        defer
        src="https://use.fontawesome.com/releases/v5.7.2/js/all.js"
        integrity="sha384-0pzryjIRos8mFBWMzSSZApWtPl/5++eIfzYmTgBBmXYdhvxPc+XcFEk+zJwDgWbP"
        crossOrigin="anonymous"
      ></script>
    </Helmet>
  );
};

export default HeadComponent;
