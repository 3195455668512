import React, { useState } from 'react';
import Popup from './popup';
import './popup.css';

const BenefitsSection = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  return (
    <section className="benefits-section py-5">
      <div className="container py-lg-5">
        <h3 className="mb-5 text-center font-weight-bold">
          Achieve More with AI Agents
        </h3>
        <div className="row">
          <div className="item col-12 col-lg-4">
            <div className="item-inner text-center p-3 p-lg-5">
              <img className="mb-3" src="assets/images/icon-target.svg" alt="" />
              <h5>Enhance Engagement</h5>
              <div>
                Deliver exceptional support 24/7 with AI Agents. Engage, resolve, and personalize interactions seamlessly. Start building loyalty today!
              </div>
            </div>
          </div>
          <div className="item col-12 col-lg-4">
            <div className="item-inner text-center p-3 p-lg-5">
              <img className="mb-3" src="assets/images/icon-rocket.svg" alt="" />
              <h5>Simplify Your Workflow</h5>
              <div>
                Automate tasks and save time with intelligent AI solutions. Let your team focus on growth while we handle the rest. Try it now!
              </div>
            </div>
          </div>
          <div className="item col-12 col-lg-4">
            <div className="item-inner text-center p-3 p-lg-5">
              <img className="mb-3" src="assets/images/icon-cogs.svg" alt="" />
              <h5>Drive Business Growth</h5>
              <div>
                Close deals faster and scale effortlessly with AI-powered automation. Transform your operations today and see the impact!
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            className="pt-3 text-center"
            onClick={openPopup}
          >
            <span className="btn btn-primary theme-btn theme-btn-ghost font-weight-bold">
              Learn More
            </span>
          </button>
        </div>
      </div>

      {/* Render the Popup component */}
      <Popup isOpen={isPopupOpen} onClose={closePopup} />
    </section>
  );
};

export default BenefitsSection;
