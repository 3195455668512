import React, { useState } from 'react';
import Popup from './popup';
import './popup.css';

const Header = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  
  const openPopup = () => {
    setIsPopupOpen(true);
  };
  
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      {/* Demo Banner */}
      <div className="demo-banner py-3 text-white text-center font-weight-bold theme-bg-secondary">
        Ready to revolutionize your business with AI Agents?{' '}
        <button 
          type="button"
          className="btn font-weight-bold ml-2 theme-btn-on-bg mt-2 mt-md-0"
          onClick={openPopup}
        >
          <i className="fas fa-external-link-alt mr-2"></i>
          Start Hiring with{' '}
          <span className="text-uppercase">SKWIPE</span>
        </button>
      </div>

      {/* Popup Component */}
      {isPopupOpen && (
        <Popup 
          isOpen={isPopupOpen} 
          onClose={closePopup} 
        />
      )}

      {/* Header Section */}
      <header className="header">
        <div className="branding">
          <div className="container position-relative">
            <nav className="navbar navbar-expand-lg">
              <h1 className="site-logo">
                <a className="navbar-brand" href="/">
                  <img className="logo-icon" src="assets/images/logo-icon.svg" alt="logo" />
                  <span className="logo-text">NOVA</span>
                </a>
              </h1>
            </nav>
            {/* Social Media Icons (Free Version Only) */}
            <ul className="social-list list-inline mb-0 position-absolute">
              <li className="list-inline-item">
                <a className="text-dark" href="#">
                  <i className="fab fa-twitter fa-fw"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a className="text-dark" href="#">
                  <i className="fab fa-facebook-f fa-fw"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a className="text-dark" href="#">
                  <i className="fab fa-instagram fa-fw"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
