import React from 'react';

const Popup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2>Activate Your AI Agents Today!</h2>
          <button 
            className="popup-close" 
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <div className="popup-inner">
{/*          <h2>Start Your AI Journey</h2>*/}
          <form>
            <input 
              type="text" 
              placeholder="Full Name" 
              className="form-control" 
              required 
            />
            <input 
              type="email" 
              placeholder="Work Email" 
              className="form-control" 
              required 
            />
            <input 
              type="tel" 
              placeholder="Phone Number" 
              className="form-control" 
            />
            <button 
              type="submit" 
              className="btn-primary"
            >
              Unlock AI Potential
            </button>
          </form>
          <div className="popup-ai-icon">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M12 8V4H8" />
              <rect width="16" height="12" x="4" y="8" rx="2" />
              <path d="M2 14h2" />
              <path d="M20 14h2" />
              <path d="M15 13v2" />
              <path d="M9 13v2" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
