import React, { useState } from 'react';
import Popup from './popup';
import './popup.css'; // Import CSS for HeroSection

const HeroSection = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  
  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);
  
  return (
    <section className="hero-section">
      <div className="container">
        <div className="row figure-holder">
          <div className="col-12 col-md-6 pt-3 pt-md-4">
            <h2 className="site-headline font-weight-bold mt-lg-5 pt-lg-5">
              Revolutionize Your Business with AI-Powered Agents
            </h2>
            <div className="site-tagline mb-3">
              <small className="tagline-highlight">
                Businesses using AI agents report saving up to $500,000 annually 
                while increasing revenue by 35% on average—start unlocking your 
                potential today!
              </small>
              <hr />
              <p>
                Harness the power of AI to engage with prospects, manage workflows, 
                and streamline operations. Our platform offers seamless automation 
                to optimize every aspect of your business processes. Start transforming 
                your business today!
              </p>
            </div>
            <div className="cta-btns">
              <div className="mb-4">
                <button 
                  className="btn btn-primary font-weight-bold theme-btn"
                  onClick={openPopup}
                >
                  Start Using AI Agents for FREE
                </button>
              </div>
            {/*  <ul className="app-stores list-unstyled list-inline mx-auto mx-md-0 d-inline-block">
                <li className="list-inline-item mr-3">
                  <a href="#" className="app-store-link">
                    <img
                      className="ios"
                      src="assets/images/appstore-apple.svg"
                      alt="App Store"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#" className="app-store-link">
                    <img
                      className="android"
                      src="assets/images/appstore-android.svg"
                      alt="Google Play"
                    />
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>

      <Popup 
        popupId="free-agents-popup"
        isOpen={isPopupOpen}
        onClose={closePopup}
      >
        <div className="popup-inner-content">
          <h2>Start Using AI Agents for FREE</h2>
          <p>Enter your details to get started with our AI agent platform.</p>
          <form>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input 
                type="email" 
                id="email"
                className="form-control" 
                placeholder="Your email" 
                required 
              />
            </div>
            <button 
              type="submit" 
              className="btn btn-primary btn-block mt-3"
            >
              Get Started
            </button>
          </form>
        </div>
      </Popup>
    </section>
  );
};

export default HeroSection;
