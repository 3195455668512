import React, { useState } from 'react';
import Popup from './popup';
import './popup.css'; // Import Popup CSS

const CTASection = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  
  const openPopup = () => {
    setIsPopupOpen(true);
  };
  
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <section className="cta-section py-5 theme-bg-secondary text-center">
      <div className="container">
        <h3 className="text-white font-weight-bold mb-3">
          Ready to Transform your Business?
        </h3>
        <div className="text-white mx-auto single-col-max-width section-intro">
          Harness the power of AI Agents to boost engagement, streamline interactions, and convert visitors into loyal app users effortlessly.
        </div>
        <button 
          type="button"
          className="btn theme-btn theme-btn-ghost theme-btn-on-bg mt-4" 
          onClick={openPopup} // Trigger the popup when clicked
        >
          Book a FREE Consultation.
        </button>
      </div>

      {/* Popup Component */}
      <Popup
        popupId="free-consultation-popup"
        isOpen={isPopupOpen}
        onClose={closePopup}
      >
        <div className="popup-inner-content">
          <h2>Book Your Free Consultation</h2>
          <p>Enter your details to schedule a free consultation with our AI experts.</p>
          <form>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input 
                type="email" 
                id="email"
                className="form-control" 
                placeholder="Your email" 
                required 
              />
            </div>
            <button 
              type="submit" 
              className="btn btn-primary btn-block mt-3"
            >
              Book Consultation
            </button>
          </form>
        </div>
      </Popup>
    </section>
  );
};

export default CTASection;
