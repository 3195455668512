import React, { useState } from 'react';

const FeaturesSection = () => {

  return (
    <section className="features-section py-5">
      <div className="container py-lg-5">
        <h3 className="mb-3 text-center font-weight-bold section-heading">
          Revolutionize your business with our AI Agents
        </h3>
        <div className="row pt-5 mb-5">
          <div className="col-12 col-md-6 col-xl-5 offset-xl-1 d-none d-md-block">
            <img
              className="product-figure product-figure-1 img-fluid"
              src="assets/images/product-figure-1.png"
              alt="Product Figure 1"
            />
          </div>

          <div className="col-12 col-md-6 col-xl-5 pr-xl-3 pt-md-3">
            <div className="card rounded border-0 shadow-lg mb-5">
              <div className="card-body p-4">
                <h5 className="card-title">
                  <i className="far fa-chart-bar mr-2 mr-lg-3 text-primary fa-lg fa-fw"></i>
                  Incident Management and Resolution
                </h5>
                <p className="card-text">
                  Dynamically optimize server workloads, balance traffic intelligently, and ensure your applications never miss a beat—even during peak demand.
                </p>
                <a href="#">Learn more <span className="more-arrow">&rarr;</span></a>
              </div>
            </div>

            <div className="card rounded border-0 shadow-lg mb-5">
              <div className="card-body p-4">
                <h5 className="card-title">
                  <i className="fas fa-laptop-code mr-2 mr-lg-3 text-primary fa-lg fa-fw"></i>
                  Intelligent Load Balancing
                </h5>
                <p className="card-text">
                  Our Agents scale resources automatically, minimizing waste and maximizing efficiency. With predictive analytics, we help you avoid downtime and save operational costs.
                </p>
                <a href="#">Learn more <span className="more-arrow">&rarr;</span></a>
              </div>
            </div>

            <div className="card rounded border-0 shadow-lg">
              <div className="card-body p-4">
                <h5 className="card-title">
                  <i className="far fa-calendar-alt mr-2 mr-lg-3 text-primary fa-lg fa-fw"></i>
                  Effortless Scheduling
                </h5>
                <p className="card-text">
                  Our AI agents intelligently schedule server tasks and resource allocations to align with your business needs. From backups to updates, everything runs at the optimal time.
                </p>
                <a href="#">Learn more <span className="more-arrow">&rarr;</span></a>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 col-xl-5 order-md-2 pr-xl-3 d-none d-md-block">
            <img
              className="product-figure product-figure-2 img-fluid"
              src="assets/images/product-figure-2.png"
              alt="Product Figure 2"
            />
          </div>

          <div className="col-12 col-md-6 col-xl-5 order-md-1 offset-xl-1 pt-xl-5">
            <div className="card rounded border-0 shadow-lg mb-5">
              <div className="card-body p-4">
                <h5 className="card-title">
                  <i className="fas fa-microphone-alt mr-2 mr-lg-3 text-primary fa-lg fa-fw"></i>
                  Elevate Customer Experiences
                </h5>
                <p className="card-text">
                  Deliver exceptional support 24/7 with AI Agents. Engage, resolve, and personalize interactions seamlessly.
                </p>
                <a href="#">Learn more <span className="more-arrow">&rarr;</span></a>
              </div>
            </div>

            <div className="card rounded border-0 shadow-lg">
              <div className="card-body p-4">
                <h5 className="card-title">
                  <i className="far fa-comments mr-2 mr-lg-3 text-primary fa-lg fa-fw"></i>
                  Simplify Your Workflow
                </h5>
                <p className="card-text">
                  Automate tasks and save time with intelligent AI solutions. Let your team focus on growth while we handle the rest.
                </p>
                <a href="#">Learn more <span className="more-arrow">&rarr;</span></a>
              </div>
            </div>
          </div>
        </div>


      </div>
    </section>
  );
};

export default FeaturesSection;
