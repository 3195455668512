import React from 'react';
import HeadComponent from './components/HeadComponent';
import Header from './components/header';
import BenefitsSection from './components/BenefitsSection';
import CTASection from './components/CTASection';
import FeaturesSection from './components/FeaturesSection';
import LogosSection from './components/LogosSection';
import TestimonialSection from './components/TestimonialSection';
import Footer from './components/Footer';
import HeroSection from './components/HeroSection';

const App = () => {
  return (
    <div>
      <HeadComponent />
      {/* Header Section */}
      <Header />
      {/* HeroSection */}
      <HeroSection />

      {/* Logos Section */}
{/*      <LogosSection /> */}

      {/* Benefits Section */}
      <BenefitsSection />

      {/* Features Section */}
      <FeaturesSection />

      {/* Call to Action Section */}
      <CTASection />

      {/* Testimonial Section */}


      {/* Footer Section */}
{/*      <Footer />*/}
    </div>
  );
}

export default App;
